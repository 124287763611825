export default function loadContact(container) {
    console.log(container);
    var PageData = {};
    PageData.addressValidated = false;
    var contactBtn = document.getElementById('contact_button');
    contactBtn.addEventListener('click', sendMessage);

}

function getInputElementsUnder(selector) {
    var formContainer = document.querySelector(selector);
    let inputElements = formContainer.querySelectorAll('input');
    return inputElements;
}

function getInputValues(elements) {
    var data = {};
    elements.forEach(function (element) {
        data[element.name] = element.value;
    });
    //ugh special cases
    data.countryName = document.querySelector('#countryDropDown').value;
    data.additionalNote = document.querySelector('#additionalNote').value;
    return data;
}

function sendMessage() {
    // user feedback
    // var icon = document.createElement('i');
    // icon.className = "fal fa-spinner-third fa-spin";
    // var button = document.getElementById("contact_button");
    // button.innerText = '';
    // button.appendChild(icon);
    // button.disabled = true;
    // collect data
    var inputs = getInputElementsUnder('.container.contact-form');
    var data = getInputValues(inputs);
    // create request and sent
    var newXHR = new XMLHttpRequest();
    newXHR.addEventListener('load', sendMessageHandler);
    newXHR.open('POST', '/contact', true);
    newXHR.setRequestHeader("Content-Type", "application/json");
    newXHR.send(JSON.stringify(data));
    console.log('contact form sent!');
}

function sendMessageHandler(event) {
    if (event.target.status === 200) {
        var form = document.querySelector('.container.contact-form');
        var parent = form.parentElement;
        form.innerHTML = "";
        addThankYouMessage(form);
    } else {
        console.log('error', event);
        //$('[data-toggle="popover"]').popover('show');
    }
}

function addThankYouMessage(container) {
    var div = document.createElement('div');
    div.className = 'text-center';
    var msg = document.createElement('h3');
    msg.className = 'messagesent-thankyou';
    msg.innerText = "Thanks!";
    div.appendChild(msg);
    container.appendChild(div);
}